const scheme = 'light'
const schemes = {
  light: {
    colors: {
      land: '#ddd',
      sea: '#fff',
      borders: '#aaa',
      // base: '#45636C', // CTHI
      // accent: '#FFD371', // CTHI
      base: '#51A091', // green
      accent: '#F1774E', // the orange color
      background: '#fff',
      foreground: '#000',
      border: '#9DBFB1'
    }
  },
  dark: {
    colors: {
      land: '#014A49',
      sea: '#001818',
      borders: '#000F1A',
      base: '#0AE780',
      background: '#001818',
      foreground: '#fff',
      accent: '#F73A55',
      border: '#9DBFB1'
    }
  },
  '2018': {
    colors: {
      land: '#E5D9CE', // #F1F1F0 | #BBBBB9 #92CBCE #D4B498 #aaa,
      sea: '#EEE2D5 ', // #CCCCD0 | #F4F1E8  #CCCBD0 #aaa
      borders: '#C1AF9F', // #C6C6C6 |
      base: '#51A091', // #1D8395 #F8EBCB ff4f00 00796B 522488
      background: 'none', // 'none' for transparent or #HEX
      foreground: '#F1774E', // #FC0D1B '#FF6B6B'
      accent: '#F1774E', // #FC0D1B '#FF6B6B'
      border: '#9DBFB1'
    }
  }
}

const colors = schemes[scheme].colors

export { colors, scheme }
