<template>
  <div class="mx-4 md:mx-8" :class="`theme-${scheme}`">
    <div class="md:flex">
      <div class="md:w-2/3 mb-4">
        <new-world-map context="country" id="map-container" />
        <div v-if="country === 'world'" class="lg:flex tems-center">
          <div class="lg:w-1/2 my-4 lg:mr-8">
            <span class="text-lg text-justify md:text-left" v-html="more"></span>
          </div>
          <bubble-chart class="lg:w-1/2"></bubble-chart>
        </div>
        <div v-else class="mt-4 lg:mt-8">
          <country-presentation />
          <country-profile />
        </div>
      </div>
      <bar-chart v-if="country === 'world'" id="bar-container" class="md:w-1/3" />
      <country-aside v-else class="md:w-1/3 md:ml-8" />
    </div>

    <div class="text-container" v-if="false">
      <div v-show="mode === 'full'">
        <div class="text">
          <span v-html="more" class="more" :class="[expandText ? 'text--expanded' : null]"></span>

          <div class="text__divider">
            <a href="#" class="drawer" @click.stop="expandText = !expandText">
              <svg class="icon icon-document" :class="[expandText ? 'icon--open' : null]">
                <use xlink:href="#icon-document"></use>
              </svg>
            </a>
            &nbsp;
            <a href="#" class="drawer" @click.stop="expandChart = !expandChart">
              <svg class="icon icon-linegraph" :class="[expandChart ? 'icon--open' : null]">
                <use xlink:href="#icon-linegraph"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import NewWorldMap from '@/components/NewWorldMap'
import BubbleChart from '@/components/BubbleChart'
import BarChart from '@/components/BarChart'

const CountryAside = () => import('@/components/CountryAside')
const CountryPresentation = () => import('@/components/CountryPresentation')
const CountryProfile = () => import('@/components/CountryProfile')

export default {
  name: 'FSI',

  components: {
    NewWorldMap,
    BarChart,
    BubbleChart,
    CountryAside,
    CountryProfile,
    CountryPresentation
  },

  data() {
    return {
      expandText: false,
      expandChart: false,
      // selectedCountry: null,
      // selectedCountryPos: null,
      // selectedCountryRadius: null,
      currentZoom: 1,
      active: null
    }
  },

  computed: {
    ...mapState({
      scheme: state => state.scheme,
      // fsi: state => state.fsi,
      // year: state => state.year,
      mode: state => state.mode
    }),
    ...mapGetters(['dimension', 'selection', 'country', 'index', 'year']),
    more() {
      return this.$store.getters.text(`${this.dimension}Text`)
    }
  },

  watch: {
    expandText(state) {
      if (state) {
        this.expandChart = false
      }
    },
    expandChart(state) {
      if (state) {
        this.expandText = false
      }
    }
  }
}
</script>
