<template>
  <nav>
    <!-- <label for="dimension-picker" class="text-lg" :class="textClass">Explore</label> -->
    <ul class="flex md:inline-flex justify-between" id="dimension-picker">
      <li v-for="(d, i) in dimensions" :key="i">
        <router-link
          :to="`/${index}/${year}/${country}/${d}/${selection}`"
          class="block font-semibold tracking-tight sm:tracking-normal text-sm md:uppercase md:py-2 md:px-4 md:rounded-full"
          :class="textClass"
        >
          {{ dimensionLabel(d) }}
        </router-link>
      </li>
    </ul>
    <p class="mt-2 ml-4 hidden md:block">{{ lead }}</p>
  </nav>
</template>

<script>
// import i18n from '@/utils/locales'
import { mapState, mapGetters } from 'vuex'
import { dimensionName } from '@/utils'

export default {
  name: 'DimensionNav',
  computed: {
    ...mapState(['scheme', 'dimensions']),
    ...mapGetters(['dimension', 'selection', 'country', 'index', 'year']),
    lead() {
      return this.$store.getters.text(`${this.dimension}Lead`)
    },
    // ...i18n.map(['pageTitle', 'lead_index', 'lead_score', 'lead_globalscaleweight']),

    textClass() {
      return this.scheme === 'light' ? 'text-gray-900' : 'text-white'
    }
  },
  methods: {
    dimensionLabel(d) {
      return dimensionName(d, this.index)
    }
  }
}
</script>
<style>
#dimension-picker .router-link-active {
  border-bottom: 2px solid theme(colors.light.base);
}

@screen md {
  #dimension-picker .router-link-active {
    color: theme(colors.white) !important;
    background-color: theme(colors.light.base);
    border-bottom: 0;
  }
}
</style>
