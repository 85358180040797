import Vue from 'vue'
import Vuex from 'vuex'

import actions from '@/store/actions'
import getters from '@/store/getters'
import mutations from '@/store/mutations'
import { scheme } from '@/utils/config.js'
import indicators from './indicators.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageTitle: 'Financial Secrecy Index',
    mode: 'full',
    year: 2022,
    years: [
      {
        index: 'fsi',
        year: 2018
      },
      {
        index: 'cthi',
        year: 2019
      },
      {
        index: 'fsi',
        year: 2020
      },
      {
        index: 'cthi',
        year: 2021
      },
      {
        index: 'fsi',
        year: 2022
      }
    ],
    files: [
      {
        index: 'fsi',
        data: [
          {
            file: '/fsi_2018.csv',
            year: 2018
          },
          {
            file: '/fsi2020.csv',
            year: 2020
          },
          {
            file: '/fsi2022.csv',
            year: 2022
          }
        ],
        schema: '/fsi_schema.json',
        texts: '/fsi_texts.json'
      },
      {
        index: 'cthi',
        data: [
          {
            file: '/cthi_2019.csv',
            year: 2019
          },
          {
            file: '/cthi_2021.csv',
            year: 2021
          }
        ],
        schema: '/cthi_schema.json',
        texts: '/cthi_texts.json'
      }
    ],
    fsi: {},
    data: {},
    // the index, fsi is default
    index: 'fsi',
    indices: ['fsi', 'cthi'],
    // the country, world is default
    country: 'world',
    countries: [],
    // dimension index (fsi value) is default
    dimension: 'index',
    dimensions: ['index', 'score', 'globalscaleweight'],
    // selection top 10 is default
    selection: 'top',
    selections: ['top', 'uk', 'oecd'],
    // selectedCountry: {
    //   data: '',
    //   position: [],
    //   radius: ''
    // },
    hover: null,
    zoom: 1,
    scheme,
    schema: {},
    mapper: {
      fsi: null,
      cthi: null
    },
    kpi: {},
    texts: {},
    iso2toiso3: {}
  },
  actions,
  getters,
  mutations,

  modules: {
    indicators
  }
})
