<template>
  <svg class="chartlegend"></svg>
</template>

<script>
import * as d3 from 'd3'
import { dimensionKey, scaleFormat, scaleSqrt } from '@/utils'
import legend from '../utils/legend'
import { mapGetters } from 'vuex'

const options = {
  canvas: {
    width: 200,
    height: 250,
    zoom: 1
  }
}

export default {
  name: 'Legend',

  computed: {
    ...mapGetters(['chartData', 'dimension'])
  },
  watch: {
    chartData() {
      this.initLegend()
    },
    dimension() {
      this.initLegend()
    }
  },

  methods: {
    initLegend() {
      d3.select('.chartlegend').remove()
      const svg = d3
        .select('.legend-container')
        .append('svg')
        .classed('chartlegend', true)

      svg.attr(
        'viewBox',
        `0 0 ${options.canvas.width / options.canvas.zoom} ${options.canvas.height / options.canvas.zoom}`
      )

      const g = svg
        .append('g')
        .attr('class', 'wrapper')
        .attr('transform', `translate(40,20) scale(${1 / options.canvas.zoom})`)

      const scale = scaleSqrt([
        d3.min(this.chartData, d => d[dimensionKey(this.dimension, this.index)]),
        d3.max(this.chartData, d => d[dimensionKey(this.dimension, this.index)])
      ]).nice()

      // initialise a legendfunction from component
      const legendFunc = legend()
        .scale(scale)
        .format(scaleFormat(this.dimension, true))
        .padding(15)
        .offset(10)
        .title('') // .title(dimension(this.dimension, true))

      g.call(legendFunc)
    }
  }
}
</script>

<style>
.legend-container {
  outline: 1px solid red;
  position: absolute;
  width: 10rem;
  bottom: 0;
  right: 0;
}
</style>
