import * as d3 from 'd3'
import { initZoom, calculateZoom } from './mapzoom'
import { colors } from '@/utils/config.js'

//import toolTip from './tooltip'
const zoom = d3.zoom()
const path = d3.geoPath().projection(null)
let currentZoom = 1
let dimension = ''
let activeCountry = null

let reset = () => true
let mouseOver = () => true
let goToCountry = () => true

/**
 * Default option values
 */
const options = {
  colors: colors,
  zoom: {
    min: 1,
    max: 1,
    gestures: false
  },
  canvas: {
    width: 960,
    // height: window.innerWidth > 1440 ? 650 : 450,
    height: 450
  }
}

function zoomToCountry(d, node) {
  const { scale, translate } = calculateZoom(d, node, path, options)

  // higlight active country
  d3.select(`path#${d.id}`)
    .style('fill', colors.accent)
    .style('fill-opacity', 0.5)

  d3.select('#map')
    .transition()
    .duration(750)
    .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale))
}

function resetZoom() {
  return d3
    .select('#map')
    .transition()
    .duration(750)
    .call(zoom.transform, d3.zoomIdentity)
}

/**
 * clickHandler
 * Handles any click on the map (countries or background
 * @param {*} d     data
 * @param {*} node  svg node
 */
function clickHandler(d, node) {
  const isCountry = d3.select(node).classed('country')
  const isCircle = d3.select(node).classed('circle')

  // reset country colors
  d3.select('.countries')
    .selectAll('path')
    .style('fill', options.colors.land)
    .style('fill-opacity', 1)

  if (isCountry || isCircle) {
    // if d is not what we expect, esscape
    if (typeof d !== 'object' || !('id' in d)) return true

    zoomToCountry(d, node)

    // if d is the same coyntry we already have, do nothing
    if (d.id === activeCountry) return true

    // update activeCountry
    activeCountry = d.id
    goToCountry(d)
  } else {
    resetZoom().on('end', () => {
      if (activeCountry) {
        activeCountry = null
        reset()
      }
    })
  }
}

export default function map() {
  /**
   * init map
   * @param  {object} selection d3 selction
   * @return {object} the map intance
   */
  function init(selection) {
    if (arguments.length) {
      const svg = selection

      svg.attr('viewBox', `0 0 ${options.canvas.width} ${options.canvas.height}`).attr('style', 'width:100%')

      // generate chart here; `this` is the element
      selection.each(function(data) {
        // this rectangle is the map background (oceans) with zoom reset event attached on clickhandler
        svg
          .append('rect')
          .attr('width', options.canvas.width)
          .attr('height', options.canvas.height)
          .style('fill', options.colors.sea)
          .style('fill-opacity', 1)
          .on('click', function(d) {
            clickHandler(d, this)
          })

        // svg groups to hold all map dom svg elements
        const g = svg.append('g')
        const countriesGroup = g.append('g').attr('class', 'countries') // create a container for countries
        const circlesGroup = g.append('g').attr('class', 'circles') // create a container for circles
        g.append('g').attr('class', 'annotation-tip annotation-tip--map') // create a container for tooltips

        // features
        countriesGroup
          .selectAll('.country')
          .data(data.topo)
          .enter()
          .append('path')
          .attr('d', path)
          .attr('id', d => d.id)
          .attr('class', d => `country ${d.properties.NAME}  ${d.properties.SUBREGION.replace(/\s+|&/g, '')}`)
          .style('fill', options.colors.land)
          .style('stroke', options.colors.borders)
          .style('stroke-width', '.25')
          .on('click', function(d) {
            clickHandler(d, this)
          })
          .on('mouseover', d => mouseOver(d.id))
          .on('mouseout', () => mouseOver(null))

        // bubbles
        // we crate a dedicated array with geoData for countries in the index
        // where we also merge in the fsi stats
        const fsiCountries = data.topo
          .map(a => ({ ...a, ...{ fsi: data.find(b => b.id === a.id) } }))
          .filter(o => o.fsi)

        const group = circlesGroup || d3.select('')
        group
          .selectAll('.circle')
          .data(fsiCountries)
          .enter()
          .append('circle')
          .attr('class', d => `circle circle-${d.id} ${d.fsi.Jurisdiction}`)
          .attr('fill', options.colors.base)
          .attr('data-region', d => d.properties.SUBREGION.replace(/\s+|&/g, ''))
          .attr('transform', d => {
            const coord = path.centroid(d)
            const pos = d.id === 'GIB' ? [coord[0] - 1, coord[1] - 6] : coord
            return `translate(${pos}) scale(${1 / currentZoom})`
          })
          .on('click', function(d) {
            clickHandler(d, this)
          })
          .on('mouseover', d => mouseOver(d.id))
          .on('mouseout', () => mouseOver(null))

        // Initalizes zoom on the map svg
        initZoom(svg, zoom, options)

        /** If the entry page is a country profil, we zoom in on that country
         * For some reasone, we must have a small delay here for the zoom to work, therfor the timeout()
         */
        if (activeCountry) {
          setTimeout(function() {
            const d = data.topo.filter(d => d.id === activeCountry)[0]
            const selection = countriesGroup.select(`path#${activeCountry}`)
            if (d && selection) {
              //clickHandler(d, selection.node()) //! Alternat option is to go via clickhandler
              zoomToCountry(d, selection.node())
            }
          }, 100)
        }
      })
    }
    return this
  }
  init.reset = function(value) {
    return arguments.length ? ((reset = value), this) : reset
  }
  init.mouseOver = function(value) {
    return arguments.length ? ((mouseOver = value), this) : mouseOver
  }
  init.goToCountry = function(value) {
    return arguments.length ? ((goToCountry = value), this) : goToCountry
  }
  init.dimension = function(value) {
    return arguments.length ? ((dimension = value), this) : dimension
  }
  init.activeCountry = function(value) {
    return arguments.length ? ((activeCountry = value), this) : activeCountry
  }
  // init.sizeScaleDomain = function(value) {
  //   return arguments.length ? ((sizeScaleDomain = value), this) : sizeScaleDomain
  // }
  // init.update = function(value) {
  //   return arguments.length ? ((update = value), this) : update
  // }
  return init
}
