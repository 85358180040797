<!-- Based on
    https://github.com/MichaelCurrie/bubble_chart
and http://vallandingham.me/bubble_charts_with_d3v4.html
-->
<template>
  <div class="chart-container">
    <svg class="bubble-chart"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { quantile } from 'd3-array'
import * as d3annotation from 'd3-svg-annotation'
import { dimensionKey, dimensionName, scaleFormat } from '@/utils'
import { colors } from '@/utils/config.js'
import { mapState, mapGetters } from 'vuex'

let svg
let g
let bubblesGroup
let annotationsGroup
let radiusScale
let nodes
let bubbles
let forceSim
let currentMode

// For scatterplots (initialized if applicable)
let xAxis
let yAxis
let xScale
let yScale

// Force layout parameters
const forceStrength = 0.04
const forceType = 'charge' // collide

// colorscale
const fillColorScale = d3
  .scaleOrdinal()
  .domain([true, false])
  .range([colors.accent, colors.base])

// Modes
const modes = [
  {
    modeId: 'index',
    type: 'scatterplot',
    xDimension: 'score',
    yDimension: 'globalscaleweight'
  },
  {
    modeId: 'globalscaleweight',
    type: 'grid',
    labels: ['Tiny', 'Small', 'Large', 'Huge'],
    gridDimensions: { rows: 1, columns: 4 },
    dataField: 'gsw',
    colorField: 'top10'
  },
  {
    modeId: 'score',
    type: 'grid',
    labels: ['< 50', '50 - 60', '60 - 70', ' > 70 '],
    gridDimensions: { rows: 1, columns: 4 },
    dataField: 'ss',
    colorField: 'top10'
  },
  {
    modeId: 'map',
    type: 'map',
    latitudeField: 'Latitude',
    longitudeField: 'Longitude'
  }
]

const options = {
  colors,
  canvas: {
    width: 960,
    height: 550,
    margin: {
      top: 40,
      right: 10,
      bottom: 120,
      left: 100
    }
  }
}

const width = options.canvas.width - options.canvas.margin.left - options.canvas.margin.right
const height = options.canvas.height - options.canvas.margin.top - options.canvas.margin.bottom

export default {
  name: 'Chart',

  computed: {
    ...mapState(['mode', 'countries', 'hover']),
    ...mapGetters(['chartData', 'dimension', 'selection', 'getCountryById', 'year', 'index']),
    scaleKey() {
      return dimensionKey(this.dimension, this.index)
    }
  },

  watch: {
    dimension() {
      this.updateDimension()
    },
    selection() {
      this.updateSelection()
    },
    year() {
      this.initChart()
    },
    hover(newVal) {
      this.highlight(newVal)
    }
  },

  methods: {
    initChart() {
      // Initialize svg and g, which we will attach all our drawing objects to.
      this.createCanvas('.chart-container')

      // Initialize the "nodes" with the data we've loaded
      nodes = this.createNodes(this.chartData)

      // Create the bubbles and the force holding them apart
      this.createBubbles()

      // Set bubble colors
      this.switchColors(this.selection)

      // Start the visualization with the current mode
      this.switchMode(this.dimension)
    },
    score(d) {
      return this.index === 'fsi' ? d.Secrecy_Score : d.Score
    },
    gswScore(d) {
      return d.Global_Scale_Weight
    },
    dataValue(d) {
      return this.index === 'fsi' ? d.FSI_Value : d.Value
    },
    /**
     * Control what happens when someone clicks a node (country)
     * Here we should just update the route
     * Or maybe also handle reset clicks ?
     */
    goToCountry(d) {
      // else re-rpute to profile page of d
      return this.$router.push(`/${this.index}/${this.year}/${d.id}/${this.dimension}/${this.selection}`)
    },

    ticked() {
      bubbles
        .each(function() {})
        .attr('cx', d => {
          return d.x
        })
        .attr('cy', d => {
          return d.y
        })
    },

    createNodes(data) {
      const that = this

      radiusScale = d3
        .scaleSqrt()
        .domain(d3.extent(data, d => this.dataValue(d)))
        .range([3, 30])

      // split the data into quantiles
      const q2 = quantile(this.chartData, 0.6, d => this.gswScore(d))
      const q3 = quantile(this.chartData, 0.87, d => this.gswScore(d))
      const q4 = quantile(this.chartData, 0.97, d => this.gswScore(d))

      const weightScale = d3
        .scaleThreshold()
        .domain([q2, q3, q4])
        .range(['Tiny', 'Small', 'Large', 'Huge'])

      const secrecyScale = d3
        .scaleThreshold()
        // .domain([40, 50, 60, 70])
        // .range(['30 - 40', '40 - 50', '50 - 60', '60 - 70', '70 - 80'])
        .domain([50, 60, 70, 100])
        .range(modes[2].labels)
      const path = d3.geoPath().projection(null)

      const myNodes = data.map(d => {
        // get X Y coordinates for map
        const pos = path.centroid(this.getCountryById(d.id))

        const node = {
          id: d.id,
          scaled_radius: radiusScale(this.dataValue(d)),
          actual_radius: d[that.scaleKey],
          // fill_color_group: d[BUBBLE_PARAMETERS.fill_color.dataField],
          // Put each node initially in a random location
          x: Math.random() * width,
          y: Math.random() * height,
          mapX: pos[0],
          mapY: pos[1],

          // groups:
          top: d.Rank < 11,
          uk: d.sov_a3 === 'gb1',
          oecd: d.isOECD,
          gsw: weightScale(this.gswScore(d)),
          ss: secrecyScale(this.score(d))
        }

        Object.entries(d).forEach(([key, value]) => {
          node[key] = value
        })
        return node
      })

      // Sort them to prevent occlusion of smaller nodes.
      myNodes.sort((a, b) => {
        return b.actual_radius - a.actual_radius
      })
      return myNodes
    },

    createBubbles() {
      // Bind nodes data to what will become DOM elements to represent them.
      bubblesGroup
        .selectAll('.bubble')
        .data(nodes, d => {
          return d.id
        })
        // Create new circle elements each with class `bubble`.
        // There will be one circle.bubble for each object in the nodes array.
        .enter()
        .append('circle')
        .attr('r', 0) // Initially, their radius (r attribute) will be 0.
        .attr('class', d => {
          return `bubble bubble-${d.id}`
        })
        .attr('fill', () => {
          return fillColorScale(false)
        }) // default base color
        .attr('stroke', () => {
          return d3.rgb(fillColorScale(false)).darker()
        })
        .attr('stroke-width', 1)
        .on('mouseover', this.mouseOver)
        .on('mouseout', this.mouseOut)
        .on('click', d => this.goToCountry(d))

      bubbles = d3.selectAll('.bubble')

      // Fancy transition to make bubbles appear, ending with the correct radius
      bubbles
        .transition()
        .duration(2000)
        .attr('r', d => {
          return d.scaled_radius
        })
    },

    createCanvas(parentDOMElement) {
      // replace is already exists
      d3.select('.bubble-chart').remove()

      // Create a SVG element inside the provided selector with viewport dimensions.
      svg = d3
        .select(parentDOMElement)
        .append('svg')
        .classed('bubble-chart', true)
        .attr('viewBox', `0 0 ${options.canvas.width} ${options.canvas.height}`)

      // Line the inside of svg with group element to contain all else
      g = svg.append('g').attr('transform', `translate(${options.canvas.margin.left},${options.canvas.margin.top})`)

      // Create a container for the map before creating the bubbles
      // Then we will draw the map inside this container, so it will appear behind the bubbles
      g.append('g').attr('class', 'world_map_container')

      // create a container for bubbles
      bubblesGroup = g.append('g').attr('class', 'bubbles')

      // create a container for tooltips
      annotationsGroup = g.append('g').attr('class', 'annotation-tip annotation-tip--bubbles')
    },

    addForceLayout(isStatic) {
      if (forceSim) {
        // Stop any forces currently in progress
        forceSim.stop()
        forceSim.nodes([]) // performance fix. Empty nodes before re-configure. ref: https://stackoverflow.com/a/21338296
      }

      // Configure the force layout holding the bubbles apart

      forceSim = d3
        .forceSimulation()
        .nodes(nodes)
        // .velocityDecay(0.3)
        .on('tick', this.ticked)

      if (!isStatic) {
        // Decide what kind of force layout to use: "collide" or "charge"
        if (forceType === 'collide') {
          const bubbleCollideForce = d3
            .forceCollide()
            .radius(d => {
              return d.scaled_radius + 0.5
            })
            .iterations(4)

          forceSim.force('collide', bubbleCollideForce)
        }

        if (forceType === 'charge') {
          const bubbleCharge = d => {
            return -(d.scaled_radius ** 2.0) * forceStrength
          }
          forceSim.force('charge', d3.forceManyBody().strength(bubbleCharge))
        }
      }
    },

    getGridTargetFunction(mode) {
      // Given a mode, return an anonymous function that maps nodes to target coordinates
      if (mode.type !== 'grid') {
        throw new Error('Error: getGridTargetFunction called with mode != "grid"')
      }

      return node => {
        let target
        // Given a mode and node, return the correct target
        if (mode.size === 1) {
          // If there is no grid, our target is the default center
          target = mode.gridCenters['']
        } else {
          // If the grid size is greater than 1, look up the appropriate target
          // coordinate using the relevant nodeTag for the mode we are in
          const nodeTag = node[mode.dataField]
          target = mode.gridCenters[nodeTag]
        }
        return target
      }
    },

    showAxis(mode) {
      /*
       *  Show the axes.
       */

      // Set up axes
      xAxis = xScale // d3.scaleBand().rangeRound([0, width]).padding(0.1);
      yAxis = yScale // d3.scaleLinear().rangeRound([height, 0]);

      g.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', `translate(0,${height + 20})`)
        .style('font-size', 14)
        .call(d3.axisBottom(xAxis).tickFormat(scaleFormat(this.dimension)))

      g.append('text')
        .attr('class', 'axis axis--x label')
        .attr('transform', `translate(${width / 2}, ${height + 10})`)
        .attr('dominant-baseline', 'hanging') // so the text is immediately below the bounding box, rather than above
        .attr('dy', '1.5em')
        .style('text-anchor', 'middle')
        .text(mode.xLabel)

      g.append('g')
        .attr('class', 'axis axis--y')
        .style('font-size', 12)
        .call(d3.axisLeft(yAxis).ticks(10, '%')) // , '%'))

      g.append('text')
        .attr('class', 'axis axis--y label')
        .attr('transform', `translate(0, ${height / 2}) rotate(-90)`) // We need to compose a rotation with a translation to place the y-axis label
        .attr('dy', '-2.5em')
        .attr('text-anchor', 'middle')
        .text(mode.yLabel)
    },

    showLabels(mode) {
      /*
       * Shows labels for each of the positions in the grid.
       */
      const currentLabels = mode.labels
      const bubbleGroupLabels = g.selectAll('.bubble_group_label').data(currentLabels)

      const gridElementHalfHeight = height / (mode.gridDimensions.rows * 2)
      const total = mode.size - 1

      // TODO: Fix clumsy tweak of x position
      bubbleGroupLabels
        .enter()
        .append('text')
        .attr('class', 'bubble_group_label')
        .attr('x', (d, i) => {
          if (i === 0) {
            return mode.gridCenters[d].x - 50
          }
          if (i === 1) {
            return mode.gridCenters[d].x - 10 * total
          }
          if (i === total - 1) {
            return mode.gridCenters[d].x + 10 * total
          }
          if (i === total) {
            return mode.gridCenters[d].x + 50
          }
          return mode.gridCenters[d].x
        })
        .attr('y', d => {
          return mode.gridCenters[d].y + gridElementHalfHeight
        })
        .attr('text-anchor', 'middle')
        // .attr('dominant-baseline', 'hanging')  // so the text is immediately below the bounding box, rather than above
        .text(d => {
          return d
        })

      // GRIDLINES FOR DEBUGGING PURPOSES
      /*
      const gridElementHalfWidth = width / (mode.gridDimensions.columns * 2);

      Object.entries(mode.gridCenters).forEach(([key, value]) => {
        g.append('rect')
          .attr('class', 'mc_debug')
          .attr('x', value.x - gridElementHalfWidth)
          .attr('y', value.y - gridElementHalfHeight)
          .attr('width', gridElementHalfWidth * 2)
          .attr('height', gridElementHalfHeight * 2)
          .attr('stroke', 'red')
          .attr('fill', 'none');
        g.append('ellipse')
          .attr('class', 'mc_debug')
          .attr('cx', value.x)
          .attr('cy', value.y)
          .attr('rx', 15)
          .attr('ry', 10);
      });
  */
      // AXIS LABEL
      g.append('text')
        .attr('class', 'axis axis--x label')
        .attr('transform', `translate(${width / 2}, ${height})`)
        .attr('dominant-baseline', 'hanging') // so the text is immediately below the bounding box, rather than above
        .attr('dy', '1.5em')
        .style('text-anchor', 'middle')
        .text(dimensionName(mode.modeId, this.index))
    },

    switchColors(selection) {
      bubbles.attr('fill', d => {
        return fillColorScale(d[selection])
      })
    },

    // This will be probably be called from updateDimension and updateSelection
    switchMode(modeId) {
      /*
       * modeId [TODO: RENAME] is expected to be a string corresponding to one of the modes.
       */
      // Get data on the new mode we have just switched to
      currentMode = this.viewMode(modeId)

      // Remove current labels
      g.selectAll('.bubble_group_label').remove()
      // Remove current debugging elements
      g.selectAll('.mc_debug').remove() // DEBUG
      // Remove axes components
      g.selectAll('.axis').remove()
      // Remove map
      g.selectAll('.world_map').remove()

      // ADJUST SVG G MARGINS (scatterplot needs extra space for axis)
      g.transition()
        .duration(350)
        .attr('transform', `translate(${70}, ${40})`)

      /*
      if (currentMode.type === 'scatterplot') {
        g.transition().duration(350)
          .attr('transform', `translate(${70}, ${40})`);
      } else {
        g.transition().duration(350)
          .attr('transform', `translate(${10}, ${-20})`);
      }
*/
      // SHOW LABELS (if we have more than one category to label)
      if (currentMode.type === 'grid' && currentMode.size > 1) {
        this.showLabels(currentMode)
      }

      // SHOW AXIS (if our mode is scatter plot)
      if (currentMode.type === 'scatterplot') {
        xScale = d3
          .scaleLinear()
          .domain(d3.extent(this.chartData, d => this.score(d)))
          .range([0, width])

        yScale = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(this.chartData, function(d) {
              return d.Global_Scale_Weight
            })
          ])
          .range([height, 0])

        // xScale = d3.scaleLog().range([0, width])
        //     .domain([dataExtents[currentMode.xDataField][0], dataExtents[currentMode.xDataField][1]]);
        // yScale = d3.scaleLog().range([height, 0])
        //     .domain([dataExtents[currentMode.yDataField][0], dataExtents[currentMode.yDataField][1]]);

        this.showAxis(currentMode)
      }

      // ADD FORCE LAYOUT (true = static so we can plot bubbles; false = the bubbles should repel about the grid centers)
      this.addForceLayout(currentMode.type === 'scatterplot' || currentMode.type === 'map')

      // SHOW MAP (if our mode is 'map')
      if (currentMode.type === 'map') {
        const path = d3.geoPath().projection(null)

        g.select('.world_map_container')
          .append('g')
          .attr('class', 'world_map')
          .selectAll('path')
          .data(this.countries)
          .enter()
          .append('path')
          .attr('d', path)
          // .attr('id', function (d) {
          //   return d.id;
          // })
          // .attr('class', function (d) {
          //   return `country ${d.properties.NAME} `;
          // })
          .style('fill', options.colors.land)
          .style('stroke', options.colors.borders)
          .style('stroke-width', '.25')
        // .on('click', function (d) {
        //   that.clicked(d, this);
        // })
      }

      // MOVE BUBBLES TO THEIR NEW LOCATIONS
      let targetFunction

      if (currentMode.type === 'grid') {
        targetFunction = this.getGridTargetFunction(currentMode)
      }

      if (currentMode.type === 'scatterplot') {
        targetFunction = d => {
          return {
            x: xScale(d[currentMode.xDataField]),
            y: yScale(d[currentMode.yDataField])
          }
        }
      }

      if (currentMode.type === 'map') {
        targetFunction = d => {
          return {
            x: d.mapX,
            y: d.mapY
          }
        }
      }

      // Given the mode we are in, obtain the node -> target mapping
      const targetForceX = d3
        .forceX(d => {
          return targetFunction(d).x
        })
        .strength(forceStrength)

      const targetForceY = d3
        .forceY(d => {
          return targetFunction(d).y
        })
        .strength(forceStrength)

      // Specify the target of the force layout for each of the circles
      forceSim.force('x', targetForceX).force('y', targetForceY)

      // Restart the force layout simulation
      forceSim.alphaTarget(1).restart()
    },

    viewMode(modeId) {
      /* ViewMode: an object that has useful parameters for each view mode.
       * initialize it with your desired view mode, then use its parameters.
       * Attributes:
       - modeIndex (which button was pressed)
       - modeId     (which button was pressed)
       - gridDimensions    e.g. {"rows": 10, "columns": 20}
       - gridCenters       e.g. {"group1": {"x": 10, "y": 20}, ...}
       - dataField    (string)
       - colorField   (string)
       - labels       (an array)
       - type         (type of grouping: "grouping" or "scatterplot")
       - size         (number of groups)
       */

      // Find which button was pressed
      let modeIndex
      for (modeIndex = 0; modeIndex < modes.length - 1; modeIndex += 1) {
        if (modes[modeIndex].modeId === modeId) {
          break
        }
      }

      // if (modeIndex >= modes.length) {
      //   console.warn("Error: can't find mode with modeId = ", modeId);
      // }

      const viewMode = {}
      const curMode = modes[modeIndex]

      viewMode.modeId = curMode.modeId
      viewMode.type = curMode.type
      viewMode.colorField = curMode.colorField

      if (viewMode.type === 'grid') {
        viewMode.gridDimensions = curMode.gridDimensions
        viewMode.labels = curMode.labels
        if (viewMode.labels === null) {
          viewMode.labels = ['']
        }
        viewMode.dataField = curMode.dataField
        viewMode.size = viewMode.labels.length

        // Loop through all grid labels and assign the centre coordinates
        viewMode.gridCenters = {}
        for (let i = 0; i < viewMode.size; i += 1) {
          const curRow = Math.floor(i / viewMode.gridDimensions.columns) // indexed starting at zero
          const curCol = i % viewMode.gridDimensions.columns // indexed starting at zero
          const currentCenter = {
            x: (2 * curCol + 1) * (width / (viewMode.gridDimensions.columns * 2)),
            y: (2 * curRow + 1) * (height / (viewMode.gridDimensions.rows * 2))
          }
          viewMode.gridCenters[viewMode.labels[i]] = currentCenter
        }
      }

      if (viewMode.type === 'scatterplot') {
        viewMode.xDataField = dimensionKey(curMode.xDimension, this.index)
        viewMode.yDataField = dimensionKey(curMode.yDimension, this.index)
        viewMode.xLabel = dimensionName(curMode.xDimension, this.index)
        viewMode.yLabel = dimensionName(curMode.yDimension, this.index)
        viewMode.xFormatString = scaleFormat(curMode.xDimension)
        viewMode.yFormatString = scaleFormat(curMode.yDimension)
      }

      if (viewMode.type === 'map') {
        viewMode.latitudeField = curMode.latitudeField
        viewMode.longitudeField = curMode.longitudeField
      }

      return viewMode
    },

    updateDimension() {
      if (forceSim) {
        // Stop any forces currently in progress.
        forceSim.stop()
      }
      this.switchMode(this.dimension)
    },

    updateSelection() {
      this.switchColors(this.selection)
    },

    // hat tip: https://bl.ocks.org/Fil/17fc857c3ce36bf8e21ddefab8bc9af4
    toolTip(bubble) {
      if (bubble) {
        const f = scaleFormat(this.dimension)
        const d = bubble.data()[0]

        // bail if no data
        if (!d) return true // bail

        const labelPrefix = this.dimension === 'map' ? 'Rank' : dimensionName(this.dimension, this.index)
        const labelValue = this.dimension === 'map' ? `#${d.Rank}` : f(d[this.scaleKey])
        const _ = currentMode.type === 'grid' ? 1 : -1
        const annotationtip = d3annotation
          .annotation()
          .type(d3annotation.annotationCalloutElbow)
          .annotations([
            {
              dx: d.x > 500 ? -50 : 50,
              dy: (d.y > 220 ? 50 : -50) * _,
              x: d.x,
              y: d.y,
              note: {
                title: d.Jurisdiction,
                label: `${labelPrefix} ${labelValue}`,
                wrap: 300
              }
            }
          ])

        annotationsGroup.call(annotationtip)
      }
    },

    highlight() {
      if (this.mode === 'full') {
        if (this.hover) {
          const selection = d3.select(`.bubble-${this.hover}`)

          // bail if no selcetion
          if (selection._groups[0][0] === null) return true // bail

          this.toolTip(selection)
        } else {
          d3.select('.annotation-tip--bubbles')
            .selectAll('g')
            .remove()
        }

        d3.selectAll('.bubble').classed('activeitem', false)
        d3.selectAll(`.bubble-${this.hover}`).classed('activeitem', true)
      }
    },

    mouseOver(d) {
      this.$store.commit('SET_HOVER', d.id)
    },

    mouseOut() {
      this.$store.commit('SET_HOVER', null)
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style>
.axis--x.label,
.axis--y.label,
.bubble_group_label {
  font-size: 21px;
  fill: #767269;
  cursor: default;
}

.bubble {
  fill-opacity: 0.75;
}
</style>
