<template>
  <div id="app" class="page container mx-auto text-gray-900">
    <div class="vld-parent">
      <loading :active.sync="isLoading"></loading>
    </div>
    <template v-if="!isLoading">
      <page-header />
      <router-view />
      <page-footer />
    </template>
  </div>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader'
import PageFooter from '@/components/layout/PageFooter'
import { mapState, mapGetters } from 'vuex'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  data() {
    return {
      isLoading: true
    }
  },
  async created() {
    /* eslint no-console: ["error", { allow: ["error"] }] */
    await this.$store.dispatch('setData').catch(err => console.error(err))
    this.isLoading = false
  },

  /**
   * use the vue-meta to set the metatags bas3ed on the route
   */
  metaInfo() {
    const title = this.meta_title
    const description = this.meta_description
    const url = this.currentUrl
    const image = this.metaImage

    return {
      title,
      description,
      titleTemplate: null,
      meta: [
        { name: 'description', content: description },
        { name: 'twitter:site', content: '@TJNNorge' },
        { property: 'og:title', content: title },
        { property: 'og:site_name', content: title },
        { property: 'og:url', content: url },
        { property: 'og:description', content: description },
        { property: 'og:image', content: image }
      ]
    }
  },

  components: {
    Loading,
    PageHeader,
    PageFooter
  },

  computed: {
    ...mapState(['scheme']),
    ...mapGetters(['meta_title', 'meta_description', 'index']),
    currentUrl() {
      return `https://fsi.taxjustice.no${this.$router.currentRoute.fullPath}`
    },
    metaImage() {
      return this.image === 'fsi'
        ? 'https://fsi.taxjustice.no/preview.png'
        : 'https://fsi.taxjustice.no/preview_cthi.png'
    }
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  },
  mounted() {
    this.toggleBodyClass('addClass', `bg-${this.scheme}`)
  },
  destroyed() {
    this.toggleBodyClass('removeClass', `bg-${this.scheme}`)
  }
}
</script>
<style>
/*
 * Themes
 */
.text-light {
  color: #000;
}
.text-dark {
  color: #fff;
}
.bg-light {
  background-color: #fff;
}
.bg-dark {
  background-color: #001818;
}
.pill-light {
  background-color: #001818;
  color: #fff;
}
.pill-dark {
  background-color: #fff;
  color: #000;
}

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: theme(colors.foreground);
}
.page {
  height: 100%;
}
.container {
  min-height: 100%;
}
</style>
