// hat tip: https://bl.ocks.org/Fil/17fc857c3ce36bf8e21ddefab8bc9af4
import { parseSvg } from 'd3-interpolate/src/transform/parse'
import * as d3 from 'd3'
import * as d3annotation from 'd3-svg-annotation'

// eslint-disable-next-line no-unused-vars
export default function toolTip(svg, selection, d, sticky) {
  const g = svg.select('g')
  const annotationsGroup = svg.select('.annotation-tip--map')

  if (selection && d) {
    const t = parseSvg(selection.attr('transform'))
    // const f = utils.scaleFormat(this.dimension);
    const annotation = {
      dx: t.translateX > 450 ? -50 : 50,
      dy: t.translateY > 100 ? -25 : 25,
      x: t.translateX,
      y: t.translateY,
      note: {
        title: d.Jurisdiction,
        // label: `Rank #${d.Rank} | ${utils.dimension(this.dimension, true)} ${f(d[this.scaleKey])}`,
        label: `Rank #${d.Rank}`,
        wrap: 300
      }
    }

    // if (sticky) {
    //   annotation.className = 'sticky'
    //   annotation.note.title = `${d.Jurisdiction}`
    //   stickyAnnotations = [annotation]
    //   annotations = stickyAnnotations
    // } else {
    //   annotations = d3.merge([stickyAnnotations, [annotation]])
    // }
    const annotations = [annotation]

    const annotationTip = d3annotation
      .annotation()
      .type(d3annotation.annotationCalloutElbow)
      .annotations(annotations)

    // attach click only for stickies
    // if (sticky) {
    //   annotationTip.on('noteclick', function () { that.showDetails(d); });
    // }

    annotationsGroup.call(annotationTip)

    // fix scaling (@todo: not needed with new click ??!?)
    g.selectAll('.annotation').attr('transform', function() {
      const { translateX, translateY } = parseSvg(d3.select(this).attr('transform'))
      return `translate(${translateX}, ${translateY}) scale(${t.scaleX})`
    })

    // if (sticky) {
    //   g.selectAll('def, use').remove()

    //   // attach click only for stickies
    //   g.select('.sticky .annotation-note-bg').on('click', function() {
    //     that.showDetails(d)
    //   })

    //   g.select('.sticky .annotation-note')
    //     .append('def')
    //     .append('path')
    //     .attr('id', 'icon')
    //     .classed('annotation__icon', true)
    //     .attr(
    //       'd',
    //       'M12.984 9v-2.016h-1.969v2.016h1.969zM12.984 17.016v-6h-1.969v6h1.969zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984z'
    //     )
    //     .style('fill', '#F1774E')
    //     .style('stroke', 'none')
    //     .on('click', function() {
    //       that.showDetails(d)
    //     })

    //   g.select('.sticky .annotation-note')
    //     .append('use')
    //     // .attr('width', 10)
    //     .attr('height', '.9em')
    //     .attr('x', t.translateX > 450 ? 5 : -25)
    //     .attr('y', t.translateY > 100 ? -40 : 0)
    //     .attr('xlink:href', '#icon')
    //     .on('click', function() {
    //       that.showDetails(d)
    //     })
    // }
  }
}
