import store from '@/store'

/**
 * @function kpiToText
 * @description converts a kpi to its frioendly text. All kpi's have location embedded in the schema
 *              the schema is used to convert the kpi back to text
 * @param {String} id - the kpi
 * @param {Boolean} useShort - (optional) some texts can have a short version
 *                              the short version will be returned if useShort === true
 */
export const kpiToText = (id, useShort = false) => {
  if (useShort) {
    return store.state.kpi[`${id}_short`] || id
  }
  return store.state.kpi[id] || id
}
