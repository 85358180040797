const SET_COUNTRIES = (state, value) => {
  Object.freeze(value)
  state.countries = value
}
// const // =  SET_COUNTRY(state, value) => {
//   state.selectedCountry = Object.assign({}, state.selectedCountry, value)
// },
const SET_FSI = (state, value) => {
  Object.freeze(value)
  state.fsi = Object.assign({}, state.fsi, value)
}
/**
 * @function SET_DATA
 * @description mutation that sets the data state after loading (of switching) dataset
 * @param {Array} value An array of data [{ year: number, data: Array( normalised row from the data )}
 */
const SET_DATA = (state, value) => {
  //Object.freeze(value.data)
  const data = {}
  value.forEach(d => {
    data[d.year] = d.data
  })
  state.data = Object.assign({}, state.data, data)
  // iterate over the data (just pick a year)
  // and map the iso2 -> is03
  if (value && value.length) {
    const iso3toIso2 = {}
    value[0].data.forEach(d => {
      if (d.ISO2 && d.Jurisdiction_ISO3) {
        iso3toIso2[d.Jurisdiction_ISO3] = d.ISO2
      }
    })
    state.iso2toiso3 = iso3toIso2
  }
}
const SET_SCHEMA = (state, schema) => {
  schema.forEach(s => {
    state.kpi[s.kpi] = s.en
    state.kpi[`${s.kpi}_short`] = s.en_short || s.en
  })
  state.schema = schema
}
// sets the texts, only english supported as of now
const SET_TEXTS = (state, texts) => {
  state.texts = {}
  for (let key in texts) {
    state.texts[key] = texts[key].en
  }
}

const SET_ZOOM = (state, value) => {
  state.zoom = value
}
const SET_HOVER = (state, value) => {
  state.hover = value
}
const SET_MODE = (state, value) => {
  state.mode = value
}
const TOGGLE_MODE = state => {
  state.mode = state.mode == 'full' ? 'details' : 'full'
}
// SET_YEAR(state, value) {
//   state.year = value
// },
// TOGGLE_YEAR(state) {
//   state.year = state.year == 2018 ? 2020 : 2018
// }
export default {
  SET_COUNTRIES,
  SET_FSI,
  SET_DATA,
  SET_SCHEMA,
  SET_TEXTS,
  SET_ZOOM,
  SET_HOVER,
  SET_MODE,
  TOGGLE_MODE
}
