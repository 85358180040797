import { parseSvg } from 'd3-interpolate/src/transform/parse'
// import { select, selectAll, event } from 'd3-selection'
// import { min, max } from 'd3-array'
// import { zoomTransform } from 'd3-zoom'
// const d3 = Object.assign({}, { select, selectAll, event, min, max, zoomTransform })
import * as d3 from 'd3'

/**
 * Initalizes zoom on the map svg
 */
function initZoom(svg, zoom, options) {
  // const svg = d3.select('#map')
  // init zoom
  zoom.scaleExtent([options.zoom.min, options.zoom.max]).on('zoom', handleZoom)
  // .on('start', this.zoomedStart)
  // .on('end', function() {
  //   // this is the new zooom level
  // })

  // limit panning to real svg bounding box limits (if an issue, this needs to be reset on window size change)
  zoom.translateExtent([
    [0, 0],
    [svg.node().getBoundingClientRect().width, svg.node().getBoundingClientRect().height]
  ])

  // Initiate Free Zoom (mouseweel, etc)
  if (options.zoom.gestures) {
    svg.call(zoom) // An alternativ to turning eveyrthing off is adding .on('wheel.zoom', null)
  }
}

/**
 * handleZoom
 * The zoom handler
 */
function handleZoom() {
  // const that = this;
  const g = d3.select('#map g')
  const transform = d3.event.transform // same as d3.zoomTransform(this);

  g.style('stroke-width', `${d3.min([1.5 / transform.k, 0.5])} px`) // tweak stroke width according to zoom
  g.style('font-size', `${d3.min([1 / transform.k, 0.5])} em`) // tweak font-size according to zoom

  g.selectAll('.circle').attr('transform', function() {
    const t = parseSvg(d3.select(this).attr('transform'))

    // test her if we can drop parseSvg ??!?
    // const t2 = d3.zoomTransform(this)

    return `translate(${t.translateX}, ${t.translateY}) scale(${1 / transform.k})`
  })

  g.selectAll('.annotation').attr('transform', function() {
    const t = parseSvg(d3.select(this).attr('transform'))
    return `translate(${t.translateX}, ${t.translateY}) scale(${1 / transform.k})`
  })

  g.attr('transform', transform) // apply transform
}

/**
 * calculateZoom
 * @param {*} d Data
 * @param {*} node Node we are zooming to
 * @param {*} path d3 path projection
 * @param {*} options options
 */
function calculateZoom(d, node, path, options) {
  const width = options.canvas.width
  const height = options.canvas.height
  const region = d3.select(node).attr('data-region')
  const bounds = path.bounds(d)

  if (region) {
    const allBounds = []
    d3.selectAll(`.${region}`).each(p => allBounds.push(path.bounds(p)))
    bounds[0][0] = d3.min(allBounds, b => b[0][0])
    bounds[0][1] = d3.min(allBounds, b => b[0][1])
    bounds[1][0] = d3.max(allBounds, b => b[1][0])
    bounds[1][1] = d3.max(allBounds, b => b[1][1])
  }

  const dx = bounds[1][0] - bounds[0][0]
  const dy = bounds[1][1] - bounds[0][1]
  const x = (bounds[0][0] + bounds[1][0]) / 2
  const y = (bounds[0][1] + bounds[1][1]) / 2

  const scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / width, dy / height)))
  const translate = [width / 2 - scale * x, height / 2 - scale * y]

  return { scale, translate }
}

export { initZoom, handleZoom, calculateZoom }
