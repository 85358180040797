<template>
  <header class="text-center px-4 md:px-8 mt-4 md:mt-8 mb-4" :class="`text-${scheme}`">
    <sub class="uppercase tracking-wider">Tax Justice Network</sub>
    <h1 class="text-xl sm:text-2xl md:text-5xl font-extrabold tracking-tight mb-4 md:mb-8">
      <router-link :to="`/${index}/${year}/world/${dimension}/${selection}`">{{ pageTitle }}</router-link>
      <!-- <router-link :to="`/${dimension}/${selection}`">{{ pageTitle }}</router-link> -->
      <!-- <span class="font-light text-light-base"> | {{ countryName }}</span> -->
    </h1>
    <div class="text-center lg:text-left flex flex-wrap lg:flex-no-wrap text-sm lg:h-32">
      <div class="mb-2 md:mb-0 w-full md:mb-4 lg:mb-0">
        <nav-label for="dimension-picker" class="md:hidden lg:block"> Explore Dimension: </nav-label>
        <dimension-nav />
      </div>
      <div class="mb-1 md:mb-0 w-1/2">
        <nav-label for="selection-picker"> Higlight the Selection:</nav-label>
        <selection-nav />
      </div>
      <div class="w-1/2">
        <nav-label for="year-picker"> {{ yearPickerText }}:</nav-label>
        <year-nav />
      </div>
    </div>
  </header>
</template>

<script>
// import i18n from '@/utils/locales'
import { mapState, mapGetters } from 'vuex'
import DimensionNav from '@/components/nav/DimensionNav'
import SelectionNav from '@/components/nav/SelectionNav'
import YearNav from '@/components/nav/YearNav'
import NavLabel from '@/components/nav/NavLabel'

export default {
  components: {
    DimensionNav,
    SelectionNav,
    YearNav,
    NavLabel
  },
  computed: {
    ...mapState(['scheme']),
    ...mapGetters(['dimension', 'selection', 'countryName', 'index', 'year']),
    // ...i18n.map(['pageTitle']),
    pageTitle() {
      return this.$store.getters.text('pageTitle')
    },
    yearPickerText() {
      return this.$store.getters.text('reportYear')
    }

    //! Before,we used to swtich title. Now I think rather but country name as subtitle
    // title() {
    //   if (this.mode === 'details') {
    //     if (this.selectedCountry && this[this.selectedCountry.id]) {
    //       return this.selectedCountry.Jurisdiction
    //     }
    //     return ''
    //   }
    //   return this.$store.state.pageTitle
    // }
  }
}
</script>
