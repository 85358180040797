import { legendSize } from 'd3-svg-legend' // d3 plugin
import { colors } from '@/utils/config.js'

// var d3 = Object.assign({}, require('d3-format'))

function legend() {
  let scale = null
  let title = null
  let format = null
  let position = null
  let orientation = 'vertical'
  let padding = 30
  let offSet = 20

  // const threshold = function({ i, genLength, generatedLabels }) {
  //   if (i === 0) {
  //     return `< ${generatedLabels[i]}`
  //   } else if (i === genLength - 1) {
  //     return `${generatedLabels[i]} >`
  //   }
  //   return generatedLabels[i]
  // }

  function my(selection) {
    // reset dom elm
    selection.select('.legend').remove()
    const g = selection.append('g').classed('legend', true)

    if (position) {
      g.attr('transform', `translate(${position.join()})`)
    }

    // the legend-library
    const _legendSize = legendSize()
      .shape('circle')
      .shapePadding(padding)
      .labelOffset(offSet)
      .labelFormat(format)
      .title(title)
      .orient(orientation)
      // .labels(threshold)
      .scale(scale)

    g.call(_legendSize)

    g.selectAll('.swatch')
      .attr('fill', colors.base)
      .style('opacity', 0.5)
  }

  // expose api
  my.scale = function(_) {
    return arguments.length ? ((scale = _), my) : my
  }
  my.title = function(_) {
    return arguments.length ? ((title = _), my) : my
  }
  my.format = function(_) {
    return arguments.length ? ((format = _), my) : my
  }
  my.position = function(_) {
    return arguments.length ? ((position = _), my) : my
  }
  my.orientation = function(_) {
    return arguments.length ? ((orientation = _), my) : my
  }
  my.padding = function(_) {
    return arguments.length ? ((padding = _), my) : my
  }
  my.offset = function(_) {
    return arguments.length ? ((offSet = _), my) : my
  }

  return my
}

export default legend
