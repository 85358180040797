import * as d3 from 'd3'
import { kpiToText } from '@/utils/text'
import { scaleThreshold } from 'd3-scale'

/**
 * @function dimensionName
 * @description returns the human readable name of a dimension from the URL
 * @param {string} dim - the dimension
 * @param {string} index - the current index (fsi | cthi)
 * @returns {string} the name of the key used tor the current scale
 */
export const dimensionName = (dim, index) => kpiToText(dimensionKey(dim, index))

/**
 * @function dimensionKey
 * @description returns the prop name used for the scaling
 * @param {string} dim - the dimension
 * @param {string} index - the current index (fsi | cthi)
 * @returns {string} the name of the key used tor the current scale
 */
export const dimensionKey = (dim, index) => {
  switch (dim) {
    case 'score':
      return index === 'fsi' ? 'Secrecy_Score' : 'Score'
    case 'globalscaleweight':
      return 'Global_Scale_Weight'
    default:
      return index === 'fsi' ? 'FSI_Value' : 'Value'
  }
}

/**
 * @function secrecyScale
 * @description The differnt inices have variying scales and domains
 * @param {String} index - the current index, the scale differs depending on the index
 * @returns {d3.scaleThreshold}
 */
export const secrecyScale = index => {
  if (index === 'cthi') {
    return scaleThreshold()
      .domain([25, 50, 75, 100])
      .range(['low', 'medium', 'high', 'extremely high'])
  }
  return scaleThreshold()
    .domain([50, 60, 70, 80, 90])
    .range(['low', 'medium', 'fairly high', 'high', 'very high', 'extremely high'])
}

/**
 * A universale placeholder for d3 square root scale
 * @param  {array}        minMAx domain array [min, max]
 * @return {function}     d3 scale
 */
export const scaleSqrt = minMAx => {
  return d3
    .scaleSqrt()
    .domain(minMAx)
    .range([3, 25])
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const dimensions = () => {
  let margin = {}
  const frameWidth = window.innerWidth
  const isMobileIsh = frameWidth <= 470

  if (isMobileIsh) {
    margin = { top: 20, right: 20, bottom: 10, left: 10 }
  } else {
    margin = { top: 30, right: 200, bottom: 50, left: 20 }
  }

  const width = Math.min(frameWidth, 970) - margin.left - margin.right
  const height = width / 1 - margin.top - margin.bottom

  return { margin, width, height }
}

/**
 * @function selection
 * @description returns the string matching the selection in the url
 * @param {String} d
 * @returns {String}
 */
export const selection = d => {
  const map = {
    top: 'Top 10',
    uk: 'UK+',
    oecd: 'OECD+'
  }
  return map[d]
}

/**
 * Uinversal formatter
 * @param  {string} d selected dimension
 * @return {function}  d3 formatter
 */
export const scaleFormat = (d, rounded) => {
  if (d === 'score') {
    return rounded ? d3.format('.1r') : d3.format('.3r')
  }
  if (d === 'globalscaleweight') {
    return rounded ? d3.format('.0%') : d3.format('.2%')
  }
  return d3.format('.0f')
}
