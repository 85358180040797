const chartData = (state, getters) => {
  return state.data[getters.year]
}
// dimension (value, secrecy score, global scale weight)
const dimension = state => state.route.params.dimension || 'index'
// the selection (top, uk, oecd)
const selection = state => state.route.params.selection || 'top'
// the sountru (iso3 or world for all)
const country = state => state.route.params.country || 'world'
// the index fsi | cthi
const index = state => state.route.params.index || 'fsi'
// the year (we should catch mismatch between index and year)
const year = state => +state.route.params.year || 2020
// get the years that are associated with this index
const years = (state, getters) => {
  const y = state.files.find(d => d.index === getters.index)
  return y.data.map(d => d.year)
}
// return all kpi's that are available for this index and year
const kpis = state => state.schema.filter(d => d.type === 'KPI').map(d => d.kpi)
// return all categories that are available for this index and year
const categories = state => state.schema.filter(d => d.type === 'category').map(d => d.kpi)

const meta_title = (state, getters) => getters.text('pageTitle')
const meta_description = (state, getters) => getters.text('description')
// return a text item if it exists
const text = state => id => state.texts[id] || ' '
// get teh iso2 from the iso3
const getIso2 = state => iso3 => state.iso2toiso3[iso3] || false
const getCountryById = state => id => state.countries.find(c => c.id === id)
const getFsiById = (state, getters) => id => getters.chartData.find(c => c.id === id)
const getCountryName = (state, getters) => id => {
  const c = getters.chartData.find(c => c.id === id)
  return c ? c.Jurisdiction : null
}
const countryName = (state, getters) => {
  const iso = getters.country
  if (iso === 'world') {
    return iso
  }
  const c = getters.chartData.find(c => c.id === iso)
  return c ? c.Jurisdiction : iso
  // const c = getters.chartData.find(c => c.id === id)
  // return c ? c.Jurisdiction : null
}
// gets the details for a country, split by available years
const countryDetails = (state, getters) => id => {
  // const index = getters.index
  // const data = state[index]
  // gets the available years for the index and sorts them smallest first
  const years = getters.years

  // check if we have the data for the latest year, return false if not
  if (!state.data[years.slice(-1)].find(c => c.id === id)) return false
  // map the data by year and output them as such
  return years.reduce((obj, year) => {
    return {
      ...obj,
      [year]: state.data[year].find(c => c.id === id)
    }
  }, {})
}

export default {
  chartData,
  dimension,
  selection,
  country,
  kpis,
  categories,
  meta_title,
  meta_description,
  index,
  year,
  years,
  text,
  getIso2,
  getCountryById,
  getFsiById,
  getCountryName,
  countryDetails,
  countryName
}
