import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import Profile from '../views/Profile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter: (to, from, next) => next('/fsi/2022/world/index/top'),
    name: 'home',
    component: Home
  },
  {
    path: '/:index/:year/:country/:dimension?/:selection?',
    name: 'profile',
    component: Home
  },
  // {
  //   path: '/profile/:country/:dimension?/:selection?',
  //   name: 'profile',
  //   component: Home
  // },
  {
    path: '/:dimension/:selection', // dimension => scope & selection => filter
    name: 'selection',
    component: Home,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
