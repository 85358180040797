<template>
  <nav>
    <ul class="flex md:inline-flex justify-center" id="selection-picker">
      <li v-for="(d, i) in selections" :key="i">
        <router-link
          :to="`/${index}/${year}/${country}/${dimension}/${d}`"
          class="block font-semibold text-sm md:uppercase mr-4 md:mr-0 md:py-2 md:px-4 md:rounded-full"
          :class="textClass"
        >
          {{ selectionLabel(d) }}
        </router-link>
      </li>
    </ul>
    <p class="mt-2 ml-4 hidden lg:block">{{ lead }}</p>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { selection } from '@/utils'

export default {
  name: 'SelectionNav',
  computed: {
    ...mapState(['scheme', 'selections']),
    ...mapGetters(['dimension', 'selection', 'country', 'index', 'year']),
    textClass() {
      return this.scheme === 'light' ? 'text-gray-900' : 'text-white'
    },
    lead() {
      return this.$store.getters.text(`${this.selection}Lead`)
    }
  },
  methods: {
    selectionLabel(d) {
      return selection(d)
    }
  }
}
</script>
<style>
#selection-picker .router-link-active {
  border-bottom: 2px solid theme(colors.light.accent);
}
@screen md {
  #selection-picker .router-link-active {
    color: theme(colors.white) !important;
    background-color: theme(colors.light.accent);
    border-bottom: 0;
  }
}
</style>
