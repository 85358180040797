<template>
  <nav>
    <ul id="year-picker" class="flex md:inline-flex justify-center">
      <li v-for="(d, i) in filteredYears" :key="i">
        <router-link
          :to="`/${index}/${d}/${country}/${dimension}/${selection}`"
          class="block font-semibold text-sm md:uppercase mr-4 md:mr-0 md:py-2 md:px-4 md:rounded-full"
          :class="textClass"
        >
          {{ d }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'YearNav',
  computed: {
    ...mapState(['year', 'years', 'scheme']),
    ...mapGetters(['index', 'dimension', 'selection', 'country']),
    // filter out the relevant years from the index
    filteredYears() {
      const indices = this.years.filter(d => d.index === this.index)
      return indices.map(d => d.year)
    },
    textClass() {
      return this.scheme === 'light' ? 'text-gray-900' : 'text-white'
    }
  }
}
</script>

<style>
#year-picker .router-link-active {
  border-bottom: 2px solid theme(colors.light.base);
}

@screen md {
  #year-picker .router-link-active {
    color: theme(colors.white) !important;
    background-color: theme(colors.light.base);
    border-bottom: 0;
  }
}
</style>
