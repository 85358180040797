<template>
  <footer class="py-16 px-4 w-full text-center text-sm text-gray-800" :class="themeClass">
    <div>
      <p class="mb-2">
        <template v-if="index === 'fsi'">
          <a href="https://www.financialsecrecyindex.com/" class="text-light-base"> Financial Secrecy Index</a>
          ©2020
        </template>
        <template v-else>
          <a href="https://cthi.taxjustice.net" class="text-light-base"> Corporate Tax Haven Index</a>
          ©2021
        </template>
        <a href="https://www.taxjustice.net/" class="text-light-base">Tax Justice Network</a>.
      </p>
      <p class="mb-2">
        Access the full database, country reports and methodology at
        <a v-if="index === 'fsi'" href="http://www.financialsecrecyindex.com" class="text-light-base">
          www.financialsecrecyindex.com
        </a>
        <a v-else href="https://cthi.taxjustice.net" class="text-light-base">
          cthi.taxjustice.net
        </a>
      </p>
      <p class="mb-2">
        Map and visualization by <a href="http://kartoteket.as/" class="text-light-base">Kartoteket</a> &amp;
        <a href="http://taxjustice.no/" class="text-light-base">Tax Justice Norway</a>. Supported by
        <a href="https://www.norad.no/" class="text-light-base">Norad</a>.
      </p>
    </div>

    <symbol id="icon-unfold_more" viewBox="0 0 24 24">
      <title>unfold_more</title>
      <path
        d="M12 18.188l3.188-3.188 1.406 1.406-4.594 4.594-4.594-4.594 1.406-1.406zM12 5.813l-3.188 3.188-1.406-1.406 4.594-4.594 4.594 4.594-1.406 1.406z"
      ></path>
    </symbol>
    <symbol id="icon-bubble_chart" viewBox="0 0 24 24">
      <title>bubble_chart</title>
      <path
        d="M10.406 8.813c0-2.672 2.109-4.828 4.781-4.828s4.828 2.156 4.828 4.828-2.156 4.781-4.828 4.781-4.781-2.109-4.781-4.781zM12.797 18c0-1.125 0.891-2.016 2.016-2.016s1.969 0.891 1.969 2.016-0.844 2.016-1.969 2.016-2.016-0.891-2.016-2.016zM3.984 14.391c0-1.781 1.453-3.188 3.234-3.188s3.188 1.406 3.188 3.188-1.406 3.188-3.188 3.188-3.234-1.406-3.234-3.188z"
      ></path>
    </symbol>
    <symbol id="icon-filter_list" viewBox="0 0 24 24">
      <title>filter_list</title>
      <path d="M6 12.984v-1.969h12v1.969h-12zM3 6h18v2.016h-18v-2.016zM9.984 18v-2.016h4.031v2.016h-4.031z"></path>
    </symbol>
    <symbol id="icon-linegraph" viewBox="0 0 42 32">
      <title>linegraph</title>
      <path
        d="M39 0c-1.654 0-3 1.346-3 3 0 0.888 0.396 1.679 1.011 2.229l-8.8 14.031c-0.371-0.165-0.78-0.26-1.211-0.26-0.868 0-1.644 0.376-2.193 0.967l-9.073-5.745c0.168-0.374 0.266-0.786 0.266-1.222 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 0.904 0.41 1.706 1.044 2.256l-6.895 10.975c-0.354-0.148-0.742-0.231-1.149-0.231-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-0.888-0.395-1.678-1.010-2.228l6.904-10.99c0.343 0.138 0.715 0.218 1.106 0.218 0.859 0 1.629-0.367 2.176-0.947l9.078 5.748c-0.161 0.368-0.254 0.772-0.254 1.199 0 1.654 1.346 3 3 3s3-1.346 3-3c0-0.863-0.371-1.636-0.957-2.184l8.81-14.046c0.354 0.147 0.741 0.23 1.147 0.23 1.654 0 3-1.346 3-3s-1.346-3-3-3zM5 29c0 1.103-0.897 2-2 2s-2-0.897-2-2 0.897-2 2-2 2 0.897 2 2zM13 15c-1.103 0-2-0.897-2-2s0.897-2 2-2 2 0.897 2 2-0.897 2-2 2zM27 24c-1.103 0-2-0.897-2-2s0.897-2 2-2 2 0.897 2 2-0.897 2-2 2zM39 5c-1.103 0-2-0.897-2-2s0.897-2 2-2 2 0.897 2 2-0.897 2-2 2z"
      ></path>
    </symbol>
    <symbol id="icon-document" viewBox="0 0 24 32">
      <title>document</title>
      <path
        d="M1.5 32h21c0.827 0 1.5-0.673 1.5-1.5v-21c0-0.017-0.008-0.031-0.009-0.047-0.002-0.023-0.008-0.043-0.013-0.065-0.017-0.071-0.046-0.135-0.090-0.191-0.007-0.009-0.006-0.020-0.013-0.029l-8-9c-0.003-0.003-0.007-0.003-0.010-0.006-0.060-0.064-0.136-0.108-0.223-0.134-0.019-0.006-0.036-0.008-0.056-0.011-0.029-0.005-0.056-0.017-0.086-0.017h-14c-0.827 0-1.5 0.673-1.5 1.5v29c0 0.827 0.673 1.5 1.5 1.5zM16 1.815l6.387 7.185h-5.887c-0.22 0-0.5-0.42-0.5-0.75v-6.435zM1 1.5c0-0.276 0.225-0.5 0.5-0.5h13.5v7.25c0 0.809 0.655 1.75 1.5 1.75h6.5v20.5c0 0.276-0.225 0.5-0.5 0.5h-21c-0.28 0-0.5-0.22-0.5-0.5v-29zM5.5 14h13c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-13c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5zM5.5 18h13c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-13c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5zM5.5 10h6c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-6c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5zM5.5 22h13c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-13c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5zM5.5 26h13c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-13c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5z"
      ></path>
    </symbol>
  </footer>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PageFooter',
  computed: {
    ...mapState(['scheme']),
    ...mapGetters(['index']),
    themeClass() {
      return this.scheme === 'light' ? 'bg-white' : 'bg-black'
    }
  }
}
</script>
<style>
/* .bg-white * {
  color: theme('colors.gray.900');
}
.bg-black * {
  color: theme('colors.white');
} */
</style>
