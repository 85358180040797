/**
 * normalizeScaleWeight
 * @param {*} d Scale Weight as string
 * Convert new percentage format back to 2018 decimal format
 */
// function normalizeScaleWeight(d) {
//   if (d.length < 6) return +d / 100
//   return +d
// }

export const createDataMapper = schema => {
  const dataMapper = {}
  schema.forEach(s => {
    if (s.dataType === 'Number' || s.dataType === 'percent') {
      dataMapper[s.kpi] = d => +d
    } else if (s.dataType === 'Boolean') {
      dataMapper[s.kpi] = d => !!+d
    } else {
      dataMapper[s.kpi] = d => d
    }
  })
  return dataMapper
}
/**
 * @function mapType
 * @description iterates over properties in the row, and mapping them to the correct datatype
 *              the mapper for this data was generated from the schema
 * @param {Object} row
 * @param {Object} mapper
 */
export const mapType = (row, mapper) => {
  for (let key in row) {
    // map the data with the generated mapper
    if (mapper[key]) {
      row[key] = mapper[key](row[key])
    } else {
      row[key] = d => d // just an identity function
    }
  }
  // map the juristiction to id
  if (row.Jurisdiction_ISO3) {
    row.id = row.Jurisdiction_ISO3
  }

  return row
}

/**
 * typeConversion
 * Mostly casts data points to numbers
 * @param {*} data object
 */
// function typeConversion(d) {
//   d.FSI_Share = +d.FSI_Share
//   d.FSI_Value = +d.FSI_Value
//   d.Global_Scale_Weight = normalizeScaleWeight(d.Global_Scale_Weight)
//   // d.Jurisdiction = d.Jurisdiction
//   // d.Jurisdiction_ISO3 = d.Jurisdiction_ISO3
//   d.KI1 = +d.KI1
//   d.KI2 = +d.KI2
//   d.KI3 = +d.KI3
//   d.KI4 = +d.KI4
//   d.KI5 = +d.KI5
//   d.KI6 = +d.KI6
//   d.KI7 = +d.KI7
//   d.KI8 = +d.KI8
//   d.KI9 = +d.KI9
//   d.KI10 = +d.KI10
//   d.KI11 = +d.KI11
//   d.KI12 = +d.KI12
//   d.KI13 = +d.KI13
//   d.KI14 = +d.KI14
//   d.KI15 = +d.KI15
//   d.KI16 = +d.KI16
//   d.KI17 = +d.KI17
//   d.KI18 = +d.KI18
//   d.KI19 = +d.KI19
//   d.KI20 = +d.KI20
//   d.Narrative_Report = d.Narrative_Report === 'Y'
//   d.Rank = +d.Rank
//   d.Secrecy_Score = +d.Secrecy_Score

//   // special extra mappings
//   d.id = d.Jurisdiction_ISO3
//   // d.x = +d.Secrecy_Score;
//   // d.y = +d.Global_Scale_Weight;
//   d.color = +d.FSI_Value
//   d.size = +d.FSI_Value
//   d.rank = +d.Rank

//   return d
// }

/**
 * extebdData
 * map topojson categories to data
 * @param {*} data
 * @param {*} geo
 * @param {String} key - the ISO3 key
 * @returns
 */
export const extendData = (data, geo, key) => {
  data.forEach(d => {
    const result = geo.filter(c => d[key] === c.id)

    d.continent = result[0] !== undefined ? result[0].properties.CONTINENT.toLowerCase() : null
    d.economy = result[0] !== undefined ? result[0].properties.ECONOMY.toLowerCase() : null
    d.income_grp = result[0] !== undefined ? result[0].properties.INCOME_GRP.toLowerCase() : null
    d.region_un = result[0] !== undefined ? result[0].properties.REGION_UN.toLowerCase() : null
    d.region_wb = result[0] !== undefined ? result[0].properties.REGION_WB.toLowerCase() : null
    d.name = result[0] !== undefined ? result[0].properties.NAME.toLowerCase() : null
    d.sovereignt = result[0] !== undefined ? result[0].properties.SOVEREIGNT.toLowerCase() : null
    d.sov_a3 = result[0] !== undefined ? result[0].properties.SOV_A3.toLowerCase() : null
    d.subregion = result[0] !== undefined ? result[0].properties.SUBREGION.toLowerCase() : null

    // get the iso code, cause it may be broken for some countries
    let iso = result[0] !== undefined ? result[0].properties.ISO_A3 : null
    /**
     * errrm
     * Right, seems the iso is -99 for england, portugal and Brussels.
     * We need code for UK, Portugal and Belgium here, so hard coded values
     */
    if (iso == -99) {
      if (d.name === 'england') {
        iso = 'GBR'
      } else if (d.name === 'portugal') {
        iso = 'PRT'
      } else if (d.name === 'brussels') {
        iso = 'BEL'
      }
    }
    d.isOECD = _isOECD(iso)
  })
  return data
}

const _isOECD = iso => {
  return OECDPlusList.includes(iso)
}

const OECDPlusList = [
  'CYM',
  'USA',
  'CHE',
  'LUX',
  'JPN',
  'NLD',
  'VGB',
  'GGY',
  'GBR',
  'DEU',
  'JEY',
  'CAN',
  'KOR',
  'IRL',
  'GIB',
  'FRA',
  'AUT',
  'ISR',
  'BMU',
  'ITA',
  'IMN',
  'AUS',
  'BEL',
  'TUR',
  'NZL',
  'POL',
  'AIA',
  'SWE',
  'LVA',
  'ESP',
  'CZE',
  'NOR',
  'HUN',
  'PRT',
  'PRI',
  'MEX',
  'CHL',
  'ISL',
  'FIN',
  'VIR',
  'TCA',
  'CUW',
  'DNK',
  'COL',
  'GRC',
  'SVK',
  'LTU',
  'ABW',
  'EST',
  'SVN',
  'MSR',
  'ASM',
  'BES',
  'IOT',
  'FLK',
  'FRO',
  'PYF',
  'GRL',
  'GUM',
  'ANT',
  'NCL',
  'MNP',
  'SXM',
  'SGS',
  'SPM',
  'WLF',
  'MAF'
]
