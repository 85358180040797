<template>
  <div class="bars-container max-h-screen overflow-y-scroll">
    <svg class="bar-chart"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
// import * as d3annotation from 'd3-svg-annotation'
import { dimensionKey, dimensionName } from '../utils'
import { colors } from '@/utils/config.js'

import { mapState, mapGetters } from 'vuex'

let g
let x = null
let y = null
//let annotationsGroup

const barIndex = d3.map()
const options = {
  canvas: {
    width: 350,
    height: 133 * 12,
    margin: {
      top: 40,
      right: 10,
      bottom: 10,
      left: 100
    }
  }
}

export default {
  name: 'BarChart',

  data() {
    return {
      svg: null
    }
  },
  computed: {
    ...mapState(['hover']),
    ...mapGetters(['chartData', 'dimension', 'selection', 'getFsiById', 'year', 'index']),
    scaleKey() {
      return dimensionKey(this.dimension, this.index)
    },
    domain() {
      return [0, d3.max(this.chartData, d => d[this.scaleKey])]
    }
  },
  watch: {
    selection() {
      this.updateSelection()
    },
    dimension() {
      this.updateDimension()
    },
    year() {
      this.initChart()
    },
    // chartData() {
    //   this.initChart()
    // },
    hover(newVal) {
      this.highlight(newVal)
    }
  },

  methods: {
    initChart() {
      const data = this.chartData.slice().sort((a, b) => d3.ascending(b[this.scaleKey], a[this.scaleKey]))

      d3.select('.bar-chart').remove()
      this.svg = d3
        .select('.bars-container')
        .append('svg')
        .classed('bar-chart', true)
        .attr('viewBox', `0 0 ${options.canvas.width} ${options.canvas.height}`)

      g = this.svg.append('g')
      //.attr('transform', `translate(${options.canvas.margin.left}, ${options.canvas.margin.top})`)

      // create a container for tooltips
      // annotationsGroup = g.append('g').attr('class', 'annotation-tip annotation-tip--bars')

      //barLength = options.canvas.width - options.canvas.margin.left - options.canvas.margin.right

      // bars
      x = d3
        .scaleLinear()
        .domain(this.domain)
        .range([options.canvas.margin.left, options.canvas.width - options.canvas.margin.right])
        .nice()

      // countries
      y = d3
        .scaleBand()
        .domain(data.map(d => d.Jurisdiction))
        .range([options.canvas.margin.top, options.canvas.height - options.canvas.margin.bottom])
        .paddingInner(0.1)

      const yAxis = g =>
        g.attr('transform', `translate(${options.canvas.margin.left},0)`).call(
          d3
            .axisLeft(y)
            .tickFormat(d => d)
            .tickSizeOuter(0)
          //.tickSizeInner(0.5)
        )

      const xAxis = g =>
        g.attr('transform', `translate(0, ${options.canvas.margin.top})`).call(
          d3
            .axisTop(x)
            .tickValues(x.ticks(2).concat([x.domain()[1]]))
            .tickFormat(() => {
              if (this.scaleKey === 'Global_Scale_Weight') return d3.format('.0%')
              return d3.format('.2')
            })
        )
      g.selectAll('.bar')
        .data(data)
        .join('rect')
        .attr('class', d => `bar bar-${d.Jurisdiction_ISO3}`)
        .style('fill', colors.base)
        .attr('x', x(0))
        .attr('y', d => y(d.Jurisdiction))
        .attr('width', d => x(d[this.scaleKey]) - x(0))
        .attr('height', () => y.bandwidth())
        .on('mouseover', this.mouseOver)
        .on('mouseout', this.mouseOut)
        .on('click', this.clickHandler)

      g.append('g')
        .attr('class', 'axis axis--y')
        .call(yAxis)

      g.append('g')
        .attr('class', 'axis axis--x')
        .call(xAxis)

      // For gsw it looks better if we remove the second to last tick
      if (this.scaleKey === 'Global_Scale_Weight') {
        d3.selectAll('.axis--x .tick')
          .filter((d, i, list) => i === list.length - 2)
          .remove()
      }

      // axis label
      g.append('text')
        .attr('class', 'axis axis--label')
        .attr(
          'transform',
          `translate(${options.canvas.width - options.canvas.margin.right}, ${options.canvas.margin.top} )`
        )
        .attr('y', -24)
        .attr('text-anchor', 'end')
        .text(`${dimensionName(this.dimension, this.index)}`)

      /*
g.append('g')
        .attr('class', 'axis axis--y')
        // .attr('transform', () => `translate(0, ${barLength})`)
        .call(d3.axisLeft(y))
        .selectAll('text')
        .style('text-anchor', 'left')
        .attr('dx', '-.8em')
        .attr('dy', '-.1em')
        .on('mouseover', function(d, i) {
          that.mouseOver(barIndex.get(i))
        })
        .on('mouseout', function(d, i) {
          that.mouseOut(barIndex.get(i))
        })
      //.attr('transform', () => 'rotate(-45)')
*/
      this.updateDimension()
      this.updateSelection()
    },

    updateDimension() {
      const dataCopy = this.chartData.slice()
      if (this.svg) {
        // Copy-on-write since tweens are evaluated after a delay.
        const y0 = y
          .domain(dataCopy.sort((a, b) => d3.ascending(b[this.scaleKey], a[this.scaleKey])).map(d => d.Jurisdiction))
          .copy()

        x.domain([0, d3.max(dataCopy, d => d[this.scaleKey])])

        this.svg.selectAll('.bar').sort((a, b) => y0(a.Jurisdiction) - y0(b.Jurisdiction))

        const transition = this.svg.transition().duration(250)
        const delay = (d, i) => i * 7

        transition
          .selectAll('.bar')
          .delay(delay)
          .attr('y', d => y0(d.Jurisdiction))
          .attr('width', d => x(d[this.scaleKey]) - x(0))

        transition
          .select('.axis.axis--y')
          .call(d3.axisLeft(y0))
          // .selectAll('g')
          .delay(delay)

        transition
          .select('.axis.axis--x')
          .call(
            d3
              .axisTop(x)
              .tickValues(x.ticks(2).concat([x.domain()[1]])) // force the endpoint tick
              .tickFormat(d => (this.scaleKey === 'Global_Scale_Weight' ? d3.format('0.0%')(d) : d3.format('.0f')(d)))
          )
          // .selectAll('g')
          .delay(delay)

        // For gsw it looks better if we remove the second to last tick
        if (this.scaleKey === 'Global_Scale_Weight') {
          d3.selectAll('.axis--x .tick')
            .filter((d, i, list) => i === list.length - 2)
            .remove()
        }

        // axis label
        transition
          .select('.axis--label')
          .delay(delay)
          .text(`${dimensionName(this.dimension, this.index)}`)

        d3.map(
          d3.selectAll('.bar').each((d, i) => {
            return barIndex.set(i, d)
          })
        )
      }
    },

    updateSelection() {
      const selection = this.selection
      const transition = this.svg.transition().duration(250)
      const delay = (d, i) => i * 7

      transition
        .selectAll('.bar')
        .style('fill', colors.base)
        .filter(d => {
          // Note that we exlcude Gibraltar and british virgin is from oecd
          if (selection === 'top') return d.Rank < 11
          if (selection === 'uk') return d.sov_a3 ? d.sov_a3.toLowerCase() === 'gb1' : false
          if (selection === 'oecd') return d.isOECD
          // {
          //   return (
          //     d.income_grp.toLowerCase() === '1. high income: oecd' &&
          //     d.name.toLowerCase() !== 'gibraltar' &&
          //     d.name.toLowerCase() !== 'british virgin is.'
          //   )
          // }
          return false
        })
        .delay(delay)
        .style('fill', colors.accent)
    },

    // hat tip: https://bl.ocks.org/Fil/17fc857c3ce36bf8e21ddefab8bc9af4
    toolTip() {
      return true
      /*
      if (selection) {
        const f = utils.scaleFormat(this.dimension)
        const bar = selection
        const barX = bar.attr('x')
        const barY = +bar.attr('y') + y.bandwidth() / 2 // XXX
        let dy = 0
        if (barY > 700) {
          dy = -50
        } else if (barY < 100) {
          dy = 50
        }

        const annotationtip = d3annotation
          .annotation()
          .type(d3annotation.annotationLabel)
          .annotations([
            {
              dx: -100,
              dy: 0,
              x: barX,
              y: barY,
              // 'text-anchor': 'end',
              note: {
                title: `${dimensionName(this.dimension, this.index)} ${f(d[this.scaleKey])}`,
                label: d.Jurisdiction,
                wrap: 300
              }
            }
          ])

        annotationsGroup.call(annotationtip)
      }
  */
    },
    highlight() {
      if (this.hover) {
        const data = this.getFsiById(this.hover)
        const selection = d3.select(`.bar-${this.hover}`)

        // bail if no data or selcetion
        if (!data || selection._groups[0][0] === null) return true // bail

        this.toolTip(selection, data)
      } else {
        d3.select('.annotation-tip--bars')
          .selectAll('g')
          .remove()
      }

      d3.selectAll('.bar').classed('activeitem', false)
      d3.selectAll(`.bar-${this.hover}`).classed('activeitem', true)
    },

    mouseOver(d) {
      this.$store.commit('SET_HOVER', d.id)
    },

    mouseOut() {
      this.$store.commit('SET_HOVER', null)
    },

    clickHandler(d) {
      return this.$router.push(`/${this.index}/${this.year}/${d.id}/${this.dimension}/${this.selection}`)
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style>
.bar {
  fill-opacity: 1;
}

.bar-chart .axis line {
  display: none;
}
.bar-chart .axis path {
  display: none;
}
.axis--label,
.bar-chart .axis text {
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 0.85em;
}

.axis--label {
  text-transform: uppercase;
  font-size: 0.5em;
}
.bg-dark .bar-chart .axis--y text {
  color: #fff;
}
.bg-light .bar-chart .axis--y text {
  color: #444;
}

.bar-chart .annotation-tip--bars {
  text-anchor: middle;
}

.bar-chart .annotation-tip--bars {
  font-size: 0.75em;
}
</style>
