export default {
  namespaced: false,
  state: {
    items: [
      { number: 1, name: 'Banking Secrecy', description: 'Does the jurisdiction provide banking secrecy?' },
      {
        number: 2,
        name: 'Trust and Foundation Register',
        description:
          'Does the jurisdiction have a central register of trusts and foundations which is publicly accessible?'
      },
      {
        number: 3,
        name: 'Recorded Company Ownership ',
        description:
          'Does the jurisdiction require non-listed companies to submit information on beneficial and legal ownership? '
      },
      {
        number: 4,
        name: 'Other Wealth Ownership ',
        description:
          'Does the jurisdiction have ownership transparency of real estate and of valuable assets stored in freeports?'
      },
      {
        number: 5,
        name: 'Limited Partnership Transparency ',
        description: 'Does the jurisdiction have transparency of limited partnerships (ownership and annual accounts?)'
      },
      {
        number: 6,
        name: 'Public Company Ownership ',
        description:
          'Does the jurisdiction require limited liability companies to publish beneficial ownership info on public records?'
      },
      {
        number: 7,
        name: 'Public Company Accounts',
        description:
          'Does the jurisdiction require limited liability companies to file and make public annual accounts?'
      },
      {
        number: 8,
        name: 'Country-by-Country Reporting ',
        description:
          'Does the jurisdiction require companies to publish financial reporting data on a country-by-country reporting basis?'
      },
      {
        number: 9,
        name: 'Corporate Tax Disclosure ',
        description:
          'Does the jurisdiction publish all unilateral cross-border tax rulings, and ensure access to BEPS Action 13 CBCRs?'
      },
      {
        number: 10,
        name: 'Legal Entity Identifier',
        description: 'Does the jurisdiction requires domestic legal entities to use the Legal Entity Identifier (LEI)?'
      },
      {
        number: 11,
        name: 'Tax Administration Capacity',
        description:
          'Does the tax administration have adequate capacity to investigate and ultimately tax people and companies?'
      },
      {
        number: 12,
        name: 'Consistent Personal Income Tax ',
        description:
          'Does the jurisdiction have a consistent Personal Income Tax regime? Does it allow buying citizenship?'
      },
      {
        number: 13,
        name: 'Avoids Promoting Tax Evasion ',
        description:
          'Does the jurisdiction avoid promoting tax evasion on worldwide capital income on interest and dividends?'
      },
      {
        number: 14,
        name: 'Tax Court Secrecy',
        description: 'Does the jurisdiction have transparency in its judicial system in tax matters?'
      },
      {
        number: 15,
        name: 'Harmful Structures ',
        description:
          'Does the jurisdiction have harmful instruments and structures within its legal and regulatory framework?'
      },
      {
        number: 16,
        name: 'Public Statistics',
        description:
          'Does the jurisdiction makes statistics on finance, trade, investments and taxes publicly available?'
      },
      {
        number: 17,
        name: 'Anti-Money Laundering',
        description: 'Does the jurisdiction meet the anti-money laundering recommendations of FATF?'
      },
      {
        number: 18,
        name: 'Automatic Information Exchange ',
        description:
          'Does the jurisdiction participate in international cooperation on automatic information exchange for tax purposes?'
      },
      {
        number: 19,
        name: 'Bilateral Treaties ',
        description: 'Does the jurisdiction have bilateral treaties for exchange of information exchange in place?'
      },
      {
        number: 20,
        name: 'International Legal Cooperation',
        description:
          'Does the jurisdiction participate in international transparency commitments and cooperation on money laundering and crime?'
      }
    ],
    groups: [
      {
        number: 1,
        name: 'Ownership Registration'
      },
      {
        number: 2,
        name: 'Legal Entity Transparency'
      },
      {
        number: 3,
        name: 'Integrity of tax and financial regulation'
      },
      {
        number: 4,
        name: 'International standards and cooperation'
      }
    ]
  }
}
