import { extendData, createDataMapper, mapType } from '@/utils/dataParser'
import * as topojson from 'topojson-client'
import * as d3 from 'd3'

const showCountry = ({ commit }, country) => {
  commit('TOGGLE_MODE')
  commit('SET_COUNTRY', country)
}

const setData = async ({ dispatch, commit, state, getters }) => {
  try {
    const index = getters.index
    /* eslint no-console: "off" */
    const files = state.files.find(d => d.index === index)

    // create the schema and the mappings for the data
    commit('SET_SCHEMA', await d3.json(files.schema))
    commit('SET_TEXTS', await d3.json(files.texts))
    const mapper = createDataMapper(state.schema)

    // load and set the country data
    await dispatch('setCountries')

    // start loading the data
    const data = await Promise.all(
      files.data.map(async d => {
        return await loadData(d.file, d.year, mapper)
      })
    )

    // extends fsi data for each coyntry with country stats and info
    data.forEach(d => extendData(d.data, state.countries, 'Jurisdiction_ISO3'))

    // save data in store
    commit('SET_DATA', data)

    // wait 3 seconds
    //await new Promise(resolve => setTimeout(resolve, 3000))
    return true
  } catch (error) {
    throw Error(error)
  }
}

// get world topology and save in store
const setCountries = async ({ commit }) => {
  const topo = await d3.json('/world2.topojson')
  const countries = topojson.feature(topo, topo.objects.countries).features
  commit('SET_COUNTRIES', countries)
}

const getData = async ({ dispatch, state }) => {
  if (state.countries.length) return state.countries
  await dispatch('setData')
  return state.countries
}

const loadData = async (file, year, mapper) => {
  const d = await d3.csv(file, d => mapType(d, mapper))
  return {
    year: year,
    data: d
  }
}

export default {
  showCountry,
  setData,
  setCountries,
  getData
}
