<template>
  <div>
    <svg class="map" id="map"></svg>

    <!-- Legend for map bubbles -->
    <div class="legend-container" v-if="showLegend">
      <chart-legend></chart-legend>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapState, mapGetters } from 'vuex'
import map from '@/charts/map/worldmap.js'
import toolTip from '@/charts/map/tooltip.js'
import { dimensionKey } from '@/utils'
import { colors } from '@/utils/config.js'

import Legend from '@/components/Legend'

export default {
  name: 'WorldMap',
  components: {
    'chart-legend': Legend
  },
  props: {
    // Ar we presenting the world opverview or a country profile
    context: {
      type: String,
      default: 'global'
    }
  },
  data() {
    return {
      chart: null,
      showLegend: false
    }
  },
  computed: {
    ...mapState(['countries', 'hover']),
    ...mapGetters(['chartData', 'getCountryById', 'getFsiById', 'dimension', 'selection', 'country', 'index', 'year']),
    scaleKey() {
      return dimensionKey(this.dimension, this.index)
    },
    domain() {
      return d3.extent(this.chartData, d => d[this.scaleKey])
    },
    sizeScale() {
      return d3
        .scaleSqrt()
        .range([3, 15])
        .domain(this.domain)
    }
  },
  methods: {
    initChart() {
      /**
       * Call map to make a new map instance
       * and pass inn click events handlers
       **/
      // await this.$store.dispatch('setData') // @todo: Find a better way to wiat for data

      d3.select('#map g').remove()
      d3.select('#map rect').remove()
      const selection = d3.select('#map')

      const mapInstance = map() // @todo. Should mapInstance be part of the componenent data ??!?!
        .reset(this.reset)
        .mouseOver(this.mouseOver)
        .goToCountry(this.goToCountry)
        .dimension(this.dimension) // @todo: NOt in use ??!?
        .activeCountry(this.country)
      //.sizeScaleDomain(this.domain)

      // add geodata to data
      this.chartData.topo = this.countries

      // bind the data to the selection and use selection.call to render the map
      this.chart = selection.datum(this.chartData).call(mapInstance)

      // set sizes and colors
      this.updateDimension()
      this.updateSelection()
    },

    /**
     * Update the map with a new scale
     */
    updateDimension() {
      const svg = this.chart
      const transition = svg
        .transition('mapRadius')
        .duration(750)
        .ease(d3.easeBounce)

      transition.selectAll('.circle').attr('r', d => this.sizeScale(d.fsi[this.scaleKey]))
    },
    /**
     * Update the selected group (color coding of circles
     */
    updateSelection() {
      const selection = this.selection
      const svg = this.chart
      const transition = svg.transition().duration(250)
      // const delay = function(d, i) {
      //   return i * 50
      // }

      transition
        .selectAll('.circle')
        .style('fill', colors.base)
        .style('opacity', 0.6)
        .filter(function(d) {
          // Note that we exlcude Gibraltar and british virgin is from oecd
          if (selection === 'top') return d.fsi.Rank < 11
          if (selection === 'uk') return d.properties.SOV_A3.toLowerCase() === 'gb1'
          if (selection === 'oecd') return d.fsi.isOECD
          return false
        })
        .delay(function(d, i) {
          return i * 50
        })
        .style('fill', colors.accent)
        .style('stroke', colors.base)
        .style('stroke-width', 0.5)
        .style('opacity', 0.8)
    },
    /**
     * Control what happens when someone clicks a node (country)
     * Here we should just update the route
     * Or maybe also handle reset clicks ?
     */
    goToCountry(d) {
      // else re-rpute to profile page of d
      return this.$router.push(`/${this.index}/${this.year}/${d.id}/${this.dimension}/${this.selection}`)
    },

    /**
     * Move from profile view to overview
     */
    reset() {
      this.$router.push(`/${this.index}/${this.year}/world/${this.dimension}/${this.selection}`)
    },

    mouseOver(cid = null) {
      // we update the hoverd state qif null (toggle off) or new
      if (cid === null || cid !== this.hover) {
        this.$store.commit('SET_HOVER', cid)
      }
    },

    toggleToolTip() {
      if (this.hover) {
        const data = this.getFsiById(this.hover)
        const selection = d3.select(`.circle-${this.hover}`)
        if (selection._groups[0][0] === null) return true // bail
        toolTip(this.chart, selection, data, false)
      } else {
        d3.select('.annotation-tip--map')
          .selectAll('.annotation:not(.sticky)')
          .remove()
      }
    }

    /**
     * Highlights a country hovered in one oh the other charts
     * Re-factor later
     */
    // highlight() {
    //   if (this.hover) {
    //     const data = this.getFsiById(this.hover)
    //     const selection = d3.select(`.circle-${this.hover}`)

    //     if (!(active.data().length && active.data()[0].id === this.hover)) {
    //       this.toolTip(selection, data, false)
    //     }
    //   } else {
    //     d3.select('.annotation-tip--map')
    //       .selectAll('.annotation:not(.sticky)')
    //       .remove()
    //   }

    //   d3.selectAll('.circle').classed('activeitem', false)
    //   d3.selectAll(`.circle-${this.hover}`).classed('activeitem', true)
    // }
  },
  watch: {
    dimension() {
      this.updateDimension()
    },
    selection() {
      this.updateSelection()
    },
    chartData() {
      this.initChart()
    },
    hover() {
      this.toggleToolTip()
    },
    // Must re-init map to trigger zoom from barchart clicks
    $route(to, from) {
      if (from.name == 'selection' && this.country) {
        this.initChart()
      }
    }
  },
  mounted() {
    this.initChart()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.circle {
  opacity: 0.6;
}
</style>
